import React, { useContext } from 'react';

import EditablePart from '../../components/EditablePart';
import FileList from '../../components/FileList';
import { FormattedId } from '../../components';
import { EmbedContext } from '../../contexts';
import { ShareQuoteButton } from '../../components/ShareButton';
import {
  EmailAsk,
  ErrorBoundary,
  LocationAsk,
  ShopInfo,
} from '../../components';
import CircuralLoading from 'app/src/components/CircuralLoading';
import NotFound from '../NotFound';
import FullScreenLoading from 'app/src/components/FullScreenLoading';

import { useQuoteEditController } from './useQuoteEditController';

interface QuoteEditerProps {
  quoteId: string;
}

// only ask for email if it has been explicitly set in the shop settings
// const askForEmail = !!shop?.settings?.isEmailRequested;
const askForEmail = false;

const QuoteEditer = ({ quoteId }: QuoteEditerProps) => {
  const { isEmbedded } = useContext(EmbedContext);
  const {
    loadingGetOrder,
    loadingScreen,
    loadingPartialUpdate,
    order,
    shop,
    inProgressFileCount,
    errorGetOrder,
    stripeClientSecret,
    errorPlaceOrder,
    disabledPlaceOrder,
    errorPartialUpdate,
    onChangeMethod,
    onChangePartOrderData,
    onAddOrderFile,
    onChangeShipping,
    onPlaceOrder,
    onCancelPlaceOrder,
  } = useQuoteEditController({ quoteId });

  if (loadingGetOrder && !order) {
    return (
      <CircuralLoading
        label={
          <>
            Quote #<FormattedId id={quoteId} />
          </>
        }
      />
    );
  }

  if (errorGetOrder?.name === 404 || errorGetOrder?.name === 401) {
    return <NotFound code={errorGetOrder?.name} />;
  }

  return (
    <div
      className={
        isEmbedded
          ? 'container-fluid bg-white py-3'
          : 'container-md bg-white p-3'
      }
      data-iframe-height="true"
    >
      <LocationAsk shopId={shop?.shop_id || ''} />

      <ErrorBoundary>
        {isEmbedded ? null : <ShopInfo shopId={shop?.shop_id || ''} />}
      </ErrorBoundary>

      <div className="grid">
        <div className="row">
          <div className="col-md">
            <h1>
              Quote <FormattedId id={quoteId} />
            </h1>
          </div>
        </div>

        <div className="row">
          <div className="col-md">
            <ShareQuoteButton quoteId={quoteId} />
          </div>
        </div>

        {askForEmail && (
          <div className="row">
            <div className="col-md">
              <ErrorBoundary>
                <EmailAsk shopId={shop?.shop_id} userId={order?.account_id} />
              </ErrorBoundary>
            </div>
          </div>
        )}

        <div className="row py-4">
          <div className="col">
            <ErrorBoundary>
              {!!order?.models?.length && (
                <FileList
                  models={order?.models}
                  loadingCount={inProgressFileCount}
                  onAddOrderFile={onAddOrderFile}
                />
              )}
            </ErrorBoundary>
          </div>
        </div>

        <div className="row">
          <div className="col-md">
            <ErrorBoundary>
              <EditablePart
                order={order}
                shop={shop}
                clientSecret={stripeClientSecret}
                errorPlaceOrder={errorPlaceOrder}
                disabledPlaceOrder={disabledPlaceOrder}
                loading={
                  loadingGetOrder || loadingScreen || loadingPartialUpdate
                }
                resetToInitial={!!errorPartialUpdate}
                onChangeMethod={onChangeMethod}
                onChangePartOrderData={onChangePartOrderData}
                onChangeShipping={onChangeShipping}
                onPlaceOrder={onPlaceOrder}
                onCancelPlaceOrder={onCancelPlaceOrder}
              />
            </ErrorBoundary>
          </div>
        </div>

        {isEmbedded ? null : <div className="segmentRectangle" />}
      </div>

      {loadingScreen && <FullScreenLoading />}
    </div>
  );
};

export default QuoteEditer;
