import React from 'react';
import { Money, PostalAddress } from '@kerfed/client';

import SafePrice from '../../SafePrice';
import AddressForm from './AddressForm';

interface ShippingProps {
  address?: PostalAddress | null;
  shippingCost?: Money | null;
  resetToInitial?: boolean;
  onChange?: (address: Partial<PostalAddress>) => void;
}

const Shipping = ({
  address,
  shippingCost,
  resetToInitial,
  onChange,
}: ShippingProps) => {
  return (
    <tr>
      <th colSpan={2}>
        <h5>Shipping Address</h5>
        <div className="mt-3">
          <AddressForm
            data={address}
            resetToInitial={resetToInitial}
            onChange={onChange}
          />
        </div>
      </th>
      <th>
        <h5>
          <SafePrice
            price={shippingCost}
            message="One or more address fields are missing required options."
          />
        </h5>
      </th>
    </tr>
  );
};

export default Shipping;
