import React from 'react';
import { PostalAddress } from '@kerfed/client';
import { TOrder } from '@kerfed/types';

import SafePrice from '../../SafePrice';
import Shipping from '../Shipping';

interface FooterPriceProps {
  order?: TOrder;
  resetToInitial?: boolean;
  onChange?: (address: Partial<PostalAddress>) => void;
}

const FooterPrice = ({ order, resetToInitial, onChange }: FooterPriceProps) => {
  return (
    <tbody className="partlist-total">
      <tr>
        <th colSpan={3}>
          <h2>Total Price</h2>
        </th>
      </tr>
      <tr>
        <th colSpan={2}>
          <h5>Fabrication Subtotal</h5>
        </th>
        <th>
          <h5>
            <SafePrice
              price={order?.total?.lines}
              message={
                (order?.total as any)?.note ||
                'One or more parts are missing required options.'
              }
            />
          </h5>
        </th>
      </tr>
      <tr>
        <th colSpan={2}>
          <h5>Discount</h5>
        </th>
        <th>
          <h5 style={{ color: 'green' }}>
            <SafePrice
              price={order?.total?.discount}
              message="Error applying discount code."
            />
          </h5>
        </th>
      </tr>

      <Shipping
        address={order?.shipping}
        shippingCost={order?.total?.shipping}
        resetToInitial={resetToInitial}
        onChange={onChange}
      />

      <tr>
        <th colSpan={2}>
          <h2>Total</h2>
        </th>
        <th>
          <h2>
            <SafePrice
              price={order?.total?.total}
              message={
                (order?.total as any)?.note ||
                'One or more parts are missing required options.'
              }
            />
          </h2>
        </th>
      </tr>
    </tbody>
  );
};

export default FooterPrice;
