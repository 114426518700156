import React from 'react';
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';

import { EmbedContext } from './contexts';

import { NavBar, Footer, ScrollToTop } from './components';
import {
  Home,
  OrderEdit,
  OrderList,
  QuoteEdit,
  QuoteList,
  QuoteNew,
  Tutorial,
  About,
  Terms,
  Technology,
  Pricing,
  NotFound,
  ShopAdmin,
  Account,
} from './pages';
import './styles/App.css';
import { QueryClient, QueryClientProvider } from 'react-query';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config_public from '../../config/src/config-public.json';
const stripePromise = loadStripe(config_public?.stripe?.public_key);

// i.e. the cache for `react-query`
const client = new QueryClient();

/**
 * Wrap a component to include route parameters in the
 * construction so we can keep `useParams` to the top
 * level and do argument passing.
 *
 * <Wrap Child={QuoteEdit}/>
 *
 * @param Component the component to wrap
 * @returns wrapped with parameters passed
 */
const Wrap = (props: any) => {
  // i.e. `/quotes/:quoteId` = {quoteId: "real-quote-id"}
  const params = useParams();
  // the component to create, i.e. `QuoteEdit`
  const { Child } = props;

  return <Child {...params} />;
};

export default () => (
  <BrowserRouter>
    <ScrollToTop />
    <div className="App">
      <QueryClientProvider client={client}>
        <EmbedContext.Consumer>
          {({ isEmbedded }) => !isEmbedded && <NavBar />}
        </EmbedContext.Consumer>
        <div className="App-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/help" element={<Tutorial />} />
            <Route path="/about" element={<About />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/methods" element={<Pricing />} />
            <Route path="/technology" element={<Technology />} />

            <Route path="/new" element={<QuoteNew shopId="kerfed" />} />
            <Route path="/quotes/" element={<QuoteList />} />
            <Route
              path="/quotes/:quoteId"
              element={<Wrap Child={QuoteEdit} />}
            />
            <Route path="/orders/" element={<OrderList shopId="kerfed" />} />
            <Route
              path="/orders/:orderId"
              element={
                <Elements stripe={stripePromise}>
                  <Wrap Child={OrderEdit} />
                </Elements>
              }
            />
            <Route path="/shops/:shopId/" element={<Wrap Child={QuoteNew} />} />
            <Route
              path="/shops/:shop/admin"
              element={<Wrap Child={ShopAdmin} />}
            />
            <Route path="/account" element={<Account />} />
            <Route path="/404" element={<NotFound code={404} />} />
            <Route path="/401" element={<NotFound code={401} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <EmbedContext.Consumer>
          {({ isEmbedded }) => !isEmbedded && <Footer />}
        </EmbedContext.Consumer>
      </QueryClientProvider>
    </div>
  </BrowserRouter>
);
