import React, { useMemo } from 'react';
import { MdCheckCircle, MdError } from 'react-icons/md';
import { PartConfiguration, MillFabrication } from '@kerfed/client';

import FileImage from '../../FileImage';
import EditablePartConfiguration from '../EditablePartConfiguration';

import { EditablePartProps } from './types';

interface EditableMillPartProps extends EditablePartProps {
  data: MillFabrication;
}

const EditableMillPart = ({
  partId,
  data,
  shop,
  config,
  onChangePartOrderData,
}: EditableMillPartProps) => {
  const quality = useMemo(() => {
    return data?.outcome?.quality || 0;
  }, [data?.outcome?.quality]);

  const handleChangePartOrderData = (newConfig: PartConfiguration) => {
    onChangePartOrderData(partId, newConfig);
  };

  return (
    <div>
      <div className="grid">
        <div className="row">
          <div className="col-md">
            <EditablePartConfiguration
              posts={data.posts}
              shop={shop}
              defaultConfig={data.defaults}
              config={config || data.defaults}
              onChangePartOrderData={handleChangePartOrderData}
            />
          </div>
          <div className="col-md">
            {data?.drawing?.url && <FileImage url={data.drawing.url} />}

            {quality < 0.96 ? (
              <p style={{ marginTop: '.75em' }}>
                <MdError style={{ color: 'red' }} /> Only{' '}
                {(quality * 100).toFixed(1)}% of part area is cuttable, check 3D
                view to see problem areas highlighted.
              </p>
            ) : (
              <p style={{ marginTop: '.75em' }}>
                <MdCheckCircle style={{ color: 'green' }} />
                {(quality * 100).toFixed(1)}% of the part area is cuttable.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditableMillPart;
