import React from 'react';
import { Money } from '@kerfed/client';

interface SafePriceProps {
  price?: Money | null;
  message?: string;
  text?: string;
}

const SafePrice = ({
  price,
  message,
  text = 'Manual Quoting Required',
}: SafePriceProps) => {
  if (!Number.isFinite(price?.amount)) {
    return (
      <div
        style={{
          textDecoration: 'underline dotted',
          textAlign: 'center',
          cursor: 'pointer',
        }}
        title={message || ''}
      >
        {text}
      </div>
    );
  }

  // format USD prices
  if (price?.currency?.toLowerCase() === 'usd') {
    return <>${((price?.amount || 0) / 100.0).toFixed(2)}</>;
  }

  return <>-</>;
};

export default SafePrice;
