import { useEffect, useMemo, useRef, useState } from 'react';
import {
  convertTimestampToDate,
  getNewExponentialBackoffTime,
} from '@kerfed/common/utils';
import { PartConfiguration, PostalAddress, Shop } from '@kerfed/client';
import { TOrder } from '@kerfed/types';

import {
  useAPIGetOrder,
  useAPIOrderLineEdit,
  useAPIOrderFileAdd,
  useAPIOrderShipping,
  useAPIOrderStripe,
} from 'app/src/api/order';
import { useAPIGetShop } from 'app/src/api/shop';

import { INITIAL_BACKOFF_TIME_MS, MAP_METHOD } from 'app/src/constants';

type TUseQuoteEditControllerParams = {
  quoteId?: string;
};

export const useQuoteEditController = ({
  quoteId = '',
}: TUseQuoteEditControllerParams) => {
  const backupOrderData = useRef<TOrder>();
  const backoffTime = useRef(INITIAL_BACKOFF_TIME_MS);
  const dateModified = useRef<Date>();
  const backoffTimeModel = useRef(INITIAL_BACKOFF_TIME_MS);
  const retryFetchOrderCount = useRef(0);
  const initialModelCount = useRef(0);
  const [order, setOrder] = useState<TOrder>();
  const [shop, setShop] = useState<Shop>();
  const [inProgressFileCount, setInProgressFileCount] = useState(0);
  const [stripeClientSecret, setStripeClientSecret] = useState('');
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [errorPlaceOrder, setErrorPlaceOrder] = useState('');

  const disabledPlaceOrder = useMemo(() => {
    if (!order?.shipping?.place_id) return true;
    if (!order?.total?.total?.amount) return true;

    return order?.lines?.some((line) => {
      if (!line?.config) return true;
      for (const key in line.config) {
        if (!line?.config?.[key]) return true;
      }
      return false;
    });
  }, [order]);

  const { onGetShop } = useAPIGetShop({
    onCompleted: (res) => {
      setShop(res);
    },
  });

  const { onOrderStripe } = useAPIOrderStripe({
    onCompleted: (res) => {
      setStripeClientSecret(res.client_secret);
    },
    onError: (err) => {
      setStripeClientSecret('');
      setErrorPlaceOrder(err?.message || 'Unexpected error.');
    },
  });

  const { onOrderShipping, error: errorOrderShipping } = useAPIOrderShipping({
    onCompleted: () => {
      handleRefreshOrderData();
    },
    onError: () => {
      setLoadingScreen(false);

      /**
       * Revert change with backup data if failed update
       */
      setOrder(backupOrderData.current);
    },
  });

  const {
    onOrderLineEdit,
    loading: lodingLineEdit,
    error: errorOrderLineEdit,
  } = useAPIOrderLineEdit({
    onCompleted: () => {
      handleRefreshOrderData();
    },
    onError: () => {
      /**
       * Revert change with backup data if failed update
       */
      setOrder(backupOrderData.current);

      /**
       * Stop all loading price when encounter an error
       */
      handleStopAllLoadingPrice();
    },
  });

  const {
    loading: loadingGetOrder,
    error,
    onGetOrder,
  } = useAPIGetOrder({
    onCompleted: (res) => {
      dateModified.current = convertTimestampToDate(
        res?.date_modified?.seconds,
      );
      const newOrderData = {
        ...res,
        lines: res?.lines?.map((line) => ({
          ...line,
          loading_price: false,
        })),
      };
      setOrder(newOrderData);

      /**
       * Store order data to different variable
       * for backup purpose
       */
      backupOrderData.current = newOrderData;

      /**
       * If request still not contains parts
       * Re-pool the data by using backoff time
       * Otherwise set the backoff time to the initial time
       */
      if (!res?.parts?.length) handlePoolInitialOrderData();
      else {
        backoffTime.current = INITIAL_BACKOFF_TIME_MS;
        setLoadingScreen(false);
      }

      /**
       * Restert the retry count to 0 if successfully fetch the data
       */
      retryFetchOrderCount.current = 0;
    },
    onError: () => {
      /**
       * Retry the request if getting error
       * and not reach maximum retry count
       *
       * Otherwise stop all loading price if exists
       */
      if (retryFetchOrderCount.current <= 3) {
        handlePoolInitialOrderData();

        /**
         * Increase the retry count after making request
         */
        retryFetchOrderCount.current = retryFetchOrderCount.current + 1;
      } else {
        handleStopAllLoadingPrice();
        setLoadingScreen(false);
      }
    },
  });

  const { onOrderFileAdd, loading: loadingAddFile } = useAPIOrderFileAdd({});

  const handleRefreshOrderData = () => {
    if (quoteId) {
      /**
       * Refresh the order data after successfully editing
       * But wait a bit before making the request
       */
      setTimeout(() => {
        onGetOrder({
          variables: {
            options: {
              pathParams: { orderId: quoteId },
              dateSince: dateModified.current,
            },
          },
        });
      }, 1500);
    }
  };

  const handleStopAllLoadingPrice = () => {
    setOrder((prev) => {
      if (!prev) return undefined;

      return {
        ...prev,
        lines: prev?.lines?.map((line) => ({
          ...line,
          loading_price: false,
        })),
      };
    });
  };

  const handleOrderLineEdit = (
    lineId: string,
    newConfig: PartConfiguration,
  ) => {
    if (!quoteId) return;

    onOrderLineEdit({
      variables: {
        options: {
          pathParams: {
            lineId,
            orderId: quoteId,
          },
        },
        params: {
          ...newConfig,
          expedite: newConfig.expedite || 'standard',
        },
      },
    });
  };

  /**
   * Initially the API will not return with the parts data
   * we should re-pull the data until we get the parts data.
   *
   * Re-pulling will be done by using the exponential backoff
   *
   */
  const handlePoolInitialOrderData = () => {
    const newBackoffTime =
      backoffTime.current + Math.round(backoffTime.current / 2);
    backoffTime.current = newBackoffTime;
    setTimeout(() => {
      onGetOrder({
        variables: {
          options: {
            pathParams: { orderId: quoteId },
            dateSince: dateModified.current,
          },
        },
      });
    }, newBackoffTime);
  };

  const handleChangeMethod = (partId: string, newSelectedMethodId: string) => {
    const part = order?.parts?.find((part) => part?.part_id === partId);
    const line = order?.lines?.find((line) => line?.config?.part_id === partId);
    const methodField = MAP_METHOD[newSelectedMethodId];

    if (!part || !line) return;

    setOrder((prev) => {
      if (!prev) return undefined;

      return {
        ...prev,
        lines: prev.lines?.map((line) => {
          if (line.config?.part_id === partId) {
            return {
              ...line,
              loading_price: true,
              config: {
                ...part[methodField].defaults,
                notes: line.config.notes,
                quantity: line.config.quantity,
              },
            };
          }

          return line;
        }),
      };
    });

    /**
     * Request to API line edit everytime
     * the user change the method
     */
    if (methodField && part?.[methodField]?.defaults && line?.line_id) {
      handleOrderLineEdit(line.line_id, {
        ...part[methodField].defaults,
        quantity: line?.config?.quantity,
        notes: line?.config?.notes,
      });
    }
  };

  const handleChangePartOrderData = (
    lineId: string,
    _: string,
    newConfig: PartConfiguration,
  ) => {
    setOrder((prev) => {
      if (!prev) return;

      return {
        ...prev,
        lines: prev?.lines?.map((line) => {
          if (line.line_id === lineId) {
            return {
              ...line,
              loading_price: true,
              config: newConfig,
            };
          }
          return line;
        }),
      };
    });
    /**
     * Request to API line edit everytime
     * the user made a change on the part configuration
     * expect change on the notes
     */
    handleOrderLineEdit(lineId, newConfig);
  };

  /**
   * Handle pooling order data after adding a new file to the
   * existing order by checking the count ot models on the API request already
   * match with the expected count or no
   *
   * @param expectedModelsCount the new expected count of the models
   */
  const handleRefetchOrderDataAfterAddFile = (expectedModelsCount: number) => {
    if (expectedModelsCount > 0 && quoteId) {
      const newBackoffTime = getNewExponentialBackoffTime(
        backoffTimeModel.current,
      );
      backoffTimeModel.current = newBackoffTime;

      onGetOrder({
        variables: {
          options: {
            pathParams: { orderId: quoteId },
            dateSince: dateModified.current,
          },
        },
        onCompleted: (res) => {
          const newModelsCount = res?.models?.length || 0;

          /**
           * If expected count smaller or equal new model count
           * make the inprogress count as empty and reset the initial backoff time
           *
           * Otherwise re-pull the order data by using backoff time
           * and recalculate how many files that are currently in progress
           * by the expected count with new modal count from the API response
           *
           */
          if (expectedModelsCount <= newModelsCount) {
            setInProgressFileCount(0);
            initialModelCount.current = 0;
            backoffTimeModel.current = INITIAL_BACKOFF_TIME_MS;
          } else {
            const tmpInProgressFileCount = expectedModelsCount - newModelsCount;
            setInProgressFileCount(tmpInProgressFileCount);
            setTimeout(() => {
              handleRefetchOrderDataAfterAddFile(expectedModelsCount);
            }, newBackoffTime);
          }
        },
      });
    }
  };

  const handleAddOrderFile = async (uploadIds: string[]) => {
    if (quoteId && uploadIds.length) {
      initialModelCount.current = order?.models?.length || 0;
      let tmpProgressFileCount = 0;
      for (const fileId of uploadIds) {
        await onOrderFileAdd({
          variables: {
            params: {
              source: {
                file_id: fileId,
              },
            },
            options: {
              pathParams: { orderId: quoteId },
            },
          },
          onCompleted: () => {
            tmpProgressFileCount += 1;
          },
        });
      }

      setInProgressFileCount(tmpProgressFileCount);
      if (tmpProgressFileCount > 0) {
        handleRefetchOrderDataAfterAddFile(
          initialModelCount.current + tmpProgressFileCount,
        );
      }
    }
  };

  const handleChangeShipping = (newShipping: Partial<PostalAddress>) => {
    setOrder((prev) => {
      if (!prev) return undefined;

      return {
        ...prev,
        shipping: {
          ...prev.shipping,
          ...newShipping,
        },
      };
    });

    setLoadingScreen(true);
    onOrderShipping({
      variables: {
        options: {
          pathParams: { orderId: quoteId },
        },
        params: {
          ...order?.shipping,
          ...newShipping,
        },
      },
    });
  };

  const handleOnPlaceOrder = () => {
    if (order?.order_id) {
      setLoadingScreen(true);
      setStripeClientSecret('');
      setErrorPlaceOrder('');
      onOrderStripe({
        variables: {
          options: {
            pathParams: { orderId: quoteId },
          },
          params: {
            order_id: order.order_id,
          },
        },
        onFinnaly: () => {
          setLoadingScreen(false);
        },
      });
    }
  };

  const handleCancelPlaceOrder = () => {
    setStripeClientSecret('');
  };

  useEffect(() => {
    if (quoteId && !order) {
      onGetOrder({
        variables: {
          options: {
            pathParams: { orderId: quoteId },
            dateSince: dateModified.current,
          },
        },
      });
    }
  }, [quoteId, order]);

  useEffect(() => {
    if (order?.shop_id && !shop) {
      onGetShop({
        variables: {
          options: { pathParams: { shopId: order.shop_id } },
        },
      });
    }
  }, [order?.shop_id, shop]);

  return {
    loadingGetOrder,
    loadingPartialUpdate: lodingLineEdit || loadingAddFile,
    errorPartialUpdate: errorOrderShipping || errorOrderLineEdit,
    order,
    shop,
    inProgressFileCount,
    stripeClientSecret,
    errorGetOrder: error,
    loadingScreen,
    errorPlaceOrder,
    disabledPlaceOrder,
    onChangeMethod: handleChangeMethod,
    onChangePartOrderData: handleChangePartOrderData,
    onAddOrderFile: handleAddOrderFile,
    onChangeShipping: handleChangeShipping,
    onPlaceOrder: handleOnPlaceOrder,
    onCancelPlaceOrder: handleCancelPlaceOrder,
  };
};
