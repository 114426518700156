import React from 'react';
import { Money } from '@kerfed/client';

import SafePrice from '../../SafePrice';

interface OptionalPriceProps {
  enabled?: boolean;
  price?: Money | null;
  message?: string | null;
}

const OptionalPrice = ({
  enabled = true,
  price,
  message,
}: OptionalPriceProps) => {
  if (!enabled) return <span>-</span>;

  return (
    <SafePrice
      price={price}
      text="No Quote"
      message={
        message ||
        'One or more part options required to compute this price are missing or invalid. Either finish selection of options, or get a manual quote via email.'
      }
    />
  );
};

export default OptionalPrice;
