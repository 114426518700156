import { EmbedContext } from '../contexts';
import React, { useContext } from 'react';
import { Remarkable } from 'remarkable';
import { useShopQuery } from '../query/queries';

/**
 * Render information about a shop.
 */
export default ({ shopId }: { shopId: string }) => {
  const { isEmbedded } = useContext(EmbedContext);
  // never display the shop info blurb on embeds
  if (!shopId || isEmbedded || shopId === 'kerfed') {
    return null;
  }

  const shopQuery = useShopQuery(shopId);
  const shop = shopQuery.data;
  const address = shop?.address;
  const blurb = !!shop?.description
    ? new Remarkable().render(shop.description)
    : '';

  return shopQuery.isLoading ? (
    <div data-iframe-height={true} className="align-center loader">
      <div className="spinner-border" role="status" />
      <h3 className="p-3">Loading Shop Details</h3>
    </div>
  ) : (
    <div>
      <div className="grid">
        <div className="row">
          <div className="col-md">
            <h1>{shop?.name || ''}</h1>
            {address?.email && (
              <a href={`mailto:${address?.email}`}>{address?.email}</a>
            )}
            {address?.phone && <p>{address?.phone}</p>}
          </div>
        </div>
        {!!blurb && (
          <div className="row">
            <div className="col-md">
              <div dangerouslySetInnerHTML={{ __html: blurb }} />
            </div>
          </div>
        )}
      </div>
      <hr />
    </div>
  );
};
