import React, { useEffect, useState } from 'react';
import { PartFabrication, Shop } from '@kerfed/client';
import { htmlToUnicode } from '@kerfed/common/utils';

import { TMethods } from '../types';
import { MAP_METHOD, PROCESS_OPTIONS } from 'app/src/constants';

interface MethodSelectorProps {
  part: PartFabrication;
  selectedMethod: string;
  shop?: Shop;
  onChange: (selectedMethod: string) => void;
}

const MethodSelector = ({
  part,
  selectedMethod,
  shop,
  onChange,
}: MethodSelectorProps) => {
  const [methods, setMethods] = useState<TMethods[]>([]);

  const handleChangeMethod = (newSelectedMethod: string) => {
    if (newSelectedMethod === selectedMethod) return;
    onChange(newSelectedMethod);
  };

  useEffect(() => {
    if (shop?.combinations?.method_id) {
      const supportedMethods = Object.keys(shop.combinations.method_id);
      const tmpMethods: TMethods[] = supportedMethods.map((method) => {
        const methodKey = MAP_METHOD?.[method];
        const objMethod = PROCESS_OPTIONS.find((m) => m.key === method);

        return {
          key: method,
          label: objMethod?.text || '',
          loaded: !!part?.[methodKey]?.outcome,
          success: !!part?.[methodKey]?.outcome?.is_success,
          errors: part?.[methodKey]?.outcome?.errors,
          error: part?.[methodKey]
            ? !!part?.[methodKey]?.outcome?.errors?.length
            : true,
        };
      });

      setMethods(tmpMethods);
    }
  }, [part, shop]);

  return (
    <div className="part-config-group pb-0">
      <div
        className="btn-group btn-method-group my-2"
        role="group"
        aria-label="Part manufacturing methods"
      >
        {methods.map((method) => {
          const disabled = !method.success || !!method.error || !method.loaded;
          return (
            <div className="col" key={`method-${method.key}`}>
              <button
                data-toggle="tooltip"
                data-placement="top"
                title={
                  method.errors?.[0]?.message ||
                  (method.success || method.error
                    ? ''
                    : 'Hang tight, still processing.')
                }
                className={`btn w-100 h-100 btn-method ${
                  method.key === selectedMethod
                    ? 'btn-primary'
                    : method.error
                      ? method.loaded
                        ? 'btn-outline-danger'
                        : 'btn-outline-secondary'
                      : 'btn-outline-primary'
                }`}
                style={{
                  pointerEvents: 'auto',
                  cursor: disabled ? 'default' : 'pointer',
                }}
                disabled={disabled}
                onClick={() => handleChangeMethod(method.key)}
              >
                {!method.success && !method.error && (
                  <span
                    className="spinner-border spinner-button"
                    role="status"
                    aria-hidden="true"
                  />
                )}

                {htmlToUnicode(method.label)}
              </button>
            </div>
          );
        })}
      </div>
      <hr />
    </div>
  );
};

export default MethodSelector;
