import React from 'react';
import { Link } from 'react-router-dom';

import logo from 'url:../images/logo_white.svg';
import '../styles/Footer.scss';

export default () => (
  <footer className="footer navbar-inverse" role="contentinfo">
    <div className="container my-3">
      <div className="row">
        <div className="col-sm-3 py-5">
          <img src={logo} className="img-fluid" />
        </div>
      </div>
      <div className="row">
        <div className="col-md">
          <ul>
            <li>
              <h3>Support</h3>
            </li>
            <li>
              <a href="mailto:support@kerfed.com" style={{ color: 'white' }}>
                support@kerfed.com&nbsp;
              </a>
            </li>
          </ul>
        </div>

        <div className="col-md">
          <ul>
            <li>
              <h3>Mechanical</h3>
            </li>

            <li>
              <Link to={`/tutorial`}>Getting Started</Link>
            </li>
            <li>
              <Link to={`/methods`}>Processes Supported</Link>
            </li>
          </ul>
        </div>

        <div className="col-md">
          <ul>
            <li>
              <h3>Corporate</h3>
            </li>
            <li>
              <Link to={`/technology`}>Technology</Link>
            </li>
            <li>
              <Link to={`/terms`}>Terms and Conditions</Link>
            </li>
          </ul>
        </div>

        <div className="col-md">
          <ul>
            <li>
              <h3>Developers</h3>
            </li>
            <li>
              <a href="https://api.kerfed.com" target="_blank">
                API Console
              </a>
            </li>
            <li>
              <a href="https://engine.gcp.kerfed.dev/api/docs" target="_blank">
                API Reference
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-md align-self-center align-center">
          &copy; 2023 Kerfed, Inc. &nbsp;&middot;&nbsp; Pittsburgh PA USA
        </div>
      </div>
    </div>
  </footer>
);
