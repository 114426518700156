import React from 'react';
import { PartConfiguration, PostalAddress } from '@kerfed/client';
import { Shop } from '@kerfed/client';
import { TOrder } from '@kerfed/types';

import EditablePartList from './EditablePartList';
import FooterPrice from './FooterPrice';
import CircuralLoading from '../CircuralLoading';
import PlaceOrderButton from './PlaceOrderButton';
import StripeCheckout from './StripeCheckout';
import EmailQuoteButton from './EmailQuoteButton';

interface EditablePartListProps {
  order?: TOrder;
  shop?: Shop;
  clientSecret?: string;
  errorPlaceOrder?: string;
  disabledPlaceOrder?: boolean;
  loading?: boolean;
  resetToInitial?: boolean;
  onChangeMethod: (partId: string, newSelectedMethodId: string) => void;
  onChangePartOrderData: (
    lineId: string,
    partId: string,
    newConfig: PartConfiguration,
  ) => void;
  onChangeShipping?: (address: Partial<PostalAddress>) => void;
  onPlaceOrder?: () => void;
  onCancelPlaceOrder?: () => void;
}

const EditablePart = ({
  order,
  shop,
  clientSecret,
  errorPlaceOrder,
  disabledPlaceOrder,
  loading,
  resetToInitial,
  onChangeMethod,
  onChangePartOrderData,
  onChangeShipping,
  onPlaceOrder,
  onCancelPlaceOrder,
}: EditablePartListProps) => {
  const handleChangeOrderData = (
    partId: string,
    newConfig: PartConfiguration,
  ) => {
    if (!order?.lines?.length) return;

    const line = order.lines.find((line) => line?.config?.part_id === partId);
    if (line?.line_id) onChangePartOrderData(line.line_id, partId, newConfig);
  };

  if (!order?.order_id) return null;

  if (!order?.parts?.length) {
    return (
      <div>
        <CircuralLoading label="Loading Parts" />
      </div>
    );
  }

  return (
    <div>
      <h1>Part List</h1>
      <div className="row">
        <div className="col-md">
          <div className="table-responsive bordered mb-3">
            <table className="table table-bordered mb-0">
              <thead>
                <tr>
                  <th>Part</th>
                  <th className="price-column">Unit Price</th>
                  <th className="price-column">Total Price</th>
                </tr>
              </thead>

              <EditablePartList
                order={order}
                shop={shop}
                onChangePartOrderData={handleChangeOrderData}
                onChangeMethod={onChangeMethod}
              />

              {!!order?.parts?.length && (
                <FooterPrice
                  order={order}
                  resetToInitial={resetToInitial}
                  onChange={onChangeShipping}
                />
              )}
            </table>
          </div>

          <div className="row g-0">
            <div className="col-md-auto">
              <EmailQuoteButton orderId={order?.order_id} />
            </div>

            <div className="col-md-auto">
              <PlaceOrderButton
                error={errorPlaceOrder}
                disabled={disabledPlaceOrder || loading}
                loading={!!loading}
                onClick={onPlaceOrder}
              />
            </div>
          </div>

          <StripeCheckout
            clientSecret={clientSecret}
            onCancel={onCancelPlaceOrder}
          />
        </div>
      </div>
    </div>
  );
};

export default EditablePart;
