import React, { useEffect, useState } from 'react';

import { Remarkable } from 'remarkable';
import logo from 'url:../images/logo_black.svg';

/**
 *
 * @param source A link to a markdown text source.
 * @returns
 */
const Article = ({ source }: { source?: string }) => {
  const render = new Remarkable();
  const [content, setContent] = useState('');
  // 3. Create out useEffect function
  useEffect(() => {
    if (!!source) {
      fetch(source)
        .then((response) => response.text())
        // 4. Setting *dogImage* to the image url that we received from the response above
        .then((data) => setContent(render.render(data)));
    }
  }, [source]);

  return (
    <div className="container-md bg-white p-3">
      {!!content ? (
        <div dangerouslySetInnerHTML={{ __html: content }} />
      ) : (
        <div className="loader loader-inline">
          <div className="spinner-border " role="status" />
        </div>
      )}
    </div>
  );
};

export function About(props: any) {
  return (
    <div className="container  bg-white p-3">
      <div className="grid p-3">
        <div className="row">
          <div className="col col-md-6">
            <img className="img w-100" src={logo} />
          </div>
        </div>
        <div className="row my-5">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h2>Company Mission</h2>
                <br />
                <p>
                  "Increase the productivity of both machine designers and
                  manufacturers through <strong>part generic</strong>{' '}
                  automation."
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h2>Background</h2>

            <p>
              Kerfed, Inc. is based in Pittsburgh, Pennsylvania and was founded
              by alumni of the
              <a href="https://www.ri.cmu.edu/"> CMU Robotics Institute, </a>
              after years of working on various research and corporate projects.
            </p>
            <p>
              Kerfed’s spiritual predecessor is{' '}
              <a href="https://www.darpa.mil/program/adaptive-vehicle-make">
                DARPA's AVM/iFAB
              </a>
              . One of the insights behind the program was that rather than
              aspiring to replace every process with slice-based 3D printing
              techniques we should create a "hardware abstraction layer". This
              layer could divide parts between different fabrication processes
              and suppliers. At the time, iFAB required a lot of manual part
              annotation which made the dream of “3D model in, HMMWV out”
              include substantial amounts of engineering time per-part.
            </p>
            <p>
              Over the years since we have developed the Kerfed Engine, an
              automated, robust and performant product. We are working to
              develop physical and software automation to help machine shops and
              manufacturers increase productivity, revenue, and ability to
              compete globally.
            </p>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md">
            <h2>Contact</h2>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>Information</td>
                  <td>
                    <a href="mailto:info@kerfed.com">info@kerfed.com</a>
                  </td>
                </tr>
                <tr>
                  <td>Support</td>
                  <td>
                    <a href="mailto:support@kerfed.com">support@kerfed.com</a>
                  </td>
                </tr>
                <tr>
                  <td>Accounting</td>
                  <td>
                    <a href="mailto:accounting@kerfed.com">
                      accounting@kerfed.com
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>(412) 532-9813</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-lg" />
        </div>
        <div className="row my-5">
          <div className="col">
            <h2>Mailing Address</h2>
            <div className="card my-2">
              <div className="card-body">
                <strong>Kerfed, Inc.</strong>
                <br />
                6425 Living Place #2121
                <br />
                Pittsburgh, PA 15206
              </div>
            </div>
          </div>
          <div className="col-md" />
        </div>
      </div>
      <div className="segmentRectangle" />
    </div>
  );
}

export function Tolerances(props: any) {
  return (
    <div className="container-md bg-white p-3">
      <div>
        <h1>Mechanical Tolerances</h1>
        <h2>Scope</h2>
        <p>
          This is the main tolerance policy, and it applies to all parts and
          overrides tolerances listed anywhere else. This tolerance policy will
          be automatically evaluated for each part, and tolerances will be
          listed in the block section of the proof drawings. If the drawings
          disagree with this policy for any reason, the less precise of the two
          numbers is what we commit to.
        </p>
        <h2>Definitions</h2>
        <div>
          <div className="card col mx-3">
            <img src="/static/images/drawing.svg" />
            <div className="card-body">
              <h5 className="card-text">
                An example of the drawings automatically generated for each
                part: note tolerance in block (click to enlarge)
              </h5>
            </div>
          </div>
        </div>
        <ul>
          <li>
            <strong>Part Length</strong>: the diagonal of an oriented bounding
            box of a part. For example, a 12”x12”x1” part would have a part
            length of 17.0”
          </li>
        </ul>
        <h2>Flat Part Tolerances</h2>
        <p>
          Kerfed offers two processes for flat parts, each of which have their
          own tolerances
        </p>
        <h3>Machined Flat Parts</h3>
        <ul>
          <li>All inner and outer profiles will have a milled finish</li>
          <li>Profile dimensions will be +/- .002”</li>
          <li>
            Thickness tolerance is +/- .003”, and top and bottom faces may or
            may not be milled
          </li>
        </ul>
        <h3>Waterjet / Laser Flat Parts</h3>
        <ul>
          <li>Inner and outer edges will have a rough surface finish</li>
          <li>
            Inner and outer edges will have a taper not to exceed 3° (.013” on a
            .25” thick part)
          </li>
          <li>
            2D features measured on one of the two faces will be +/- .004” for
            part length under 24”
          </li>
          <li>
            2D features measured on one of the two faces will be +/- .010” for
            part length over 24”
          </li>
          <li>
            Top and bottom faces will not be milled (stock plate/bar stock
            surface finish)
          </li>
          <li>
            If a reaming operation is specified, the hole diameter is +/- .0005”
          </li>
        </ul>
        <h2>Bent Parts Tolerances</h2>
        <ul>
          <li>Bends will be +/- 2°</li>
          <li>
            Features in flat regions of the part will accurate relative to other
            features in that same flat region to +/-.004”
          </li>
        </ul>
        <h2>COTS Parts</h2>
        <ul>
          <li>Parts are globally sourced</li>
          <li>
            Trade size bearings and pins generally have standard mechanical
            properties, but no guarantees are made other than that the part
            numbers will match.
          </li>
        </ul>
        <div className="segmentRectangle" />
      </div>
    </div>
  );
}

export function Terms(props: any) {
  return (
    <div>
      <Article source="/static/content/terms.md" />;
      <br />
      <Article source="/static/content/privacy.md" />;
    </div>
  );
  return;
}

export function Pricing(props: any) {
  return (
    <div className="container-md bg-white p-3">
      <h1>Intelligent Manufacturing Engine</h1>
      <div className="grid">
        <div className="row my-auto">
          <div className="card col mx-3">
            <img src="/static/images/hillclimb.jpg" />
            <div className="card-body">
              <h3 className="card-title">
                kerfed.com: Multi-Shop Optimization
              </h3>
              <h5 className="card-text">
                kerfed.com provides a convenient way to split jobs between
                multiple machine shops. Parts are automatically allocated to the
                cheapest machine for each part across multiple machine shops.
                When orders are placed each part is sent directly to the most
                competitive shop for each part.
              </h5>
            </div>
          </div>
          <div className="card col mx-3">
            <img src="/static/images/vf2.jpg" />
            <div className="card-body">
              <h3 className="card-title">Shop Direct</h3>
              <h5 className="card-text">
                The Kerfed Engine is embedded in the web pages of partner
                machine shops, so if you prefer to deal with a local shop
                directly you can go straight to your preferred shop.
              </h5>
            </div>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md">
            <h2>Pricing</h2>
            <p>
              Parts are quoted by calculating a <b>cycle time</b> for each part
              based on parameters like feed rate curves, plunge times, etc.
              Prices are generated by adding material costs to the cycle time
              multiplied by a <b>work rate</b> for a particular machine. The
              work rate is the fully burdened (labor, capital, operating, etc)
              billable rate of a particular machine in USD per minute.
            </p>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md">
            <h2>Supported Manufacturing Processes</h2>

            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Part Type</th>
                  <th>Typical Machines</th>
                  <th>Part Geometry Restrictions</th>
                  <th>Design Notes</th>
                  <th>Release To Manufacturing Format</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>Flat</b>
                  </td>
                  <td>
                    <a href="https://www.omax.com/omax-waterjet/60120">
                      Omax Waterjet
                    </a>
                    {', '}
                    <a href="https://www.bystronic.com/en/products/laser-cutting-systems/BySprintFiber.php">
                      Bystronic Fiber Laser
                    </a>
                    {', '}
                    <a href="https://www.epiloglaser.com/laser-machines/legend-laser-series.htm">
                      CO2 Laser
                    </a>
                    {', '}
                    <a href="https://lagunatools.com/cnc-plasma-cutter/cutmaster-level-2-8/">
                      CNC Plasma Cutter
                    </a>
                  </td>
                  <td>
                    Flat parts must be smaller than
                    60&rdquo;x120&rdquo;x4&rdquo; , with a minimum radius of
                    0.01&rdquo; &nbsp;and minimum hole size of 0.050&rdquo;
                  </td>
                  <td>
                    Parts less than 0.25&rdquo; thick can usually be cut on a
                    fiber laser, which is roughly 10x faster than a waterjet.
                  </td>
                  <td>
                    DXF files, one file per material thickness nested with
                    ordered quantities
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Bent</b>
                  </td>
                  <td>
                    <a href="https://www.amada.com/america/down-acting-hg-5020">
                      Amada CNC Press Brake
                    </a>
                  </td>
                  <td>
                    Part thickness must be 0.375&rdquo; &nbsp;or less. Bends
                    must be cylindrical and under 8&rsquo; long and between
                    0.06&rdquo; and 2&rdquo; in diameter. Flanges must be 3
                    times thickness or longer.
                  </td>
                  <td>
                    To ensure bendable geometry, bent parts must &nbsp;be
                    designed using the sheet metal tools package in your CAD
                    system. Assemblies containing PEM nuts will automatically
                    detect and add the nut installation.
                  </td>
                  <td>
                    Nested flat pattern DXF in correct quantities; separate per-
                    part file including bend lines (K- factors set in shop
                    parameters)
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Rolled</b>
                  </td>
                  <td>
                    <a href="https://www.elitemetaltools.com/tool-shop/products/baileigh-plate-roll-pr-403?sku%3DPR-403">
                      Plate Roller
                    </a>
                  </td>
                  <td>
                    0.060&rdquo; thick material or less has a 1&rdquo; minimum
                    internal radius, 36&rdquo; &nbsp;max height, 0.060&rdquo;-
                    0.1875&rdquo; thick has a 5.5&rdquo; minimum radius,
                    48&rdquo; &nbsp;max height
                  </td>
                  <td>
                    Holes should generally be smaller than roughly half of the
                    rolled part radius.{' '}
                    <a href="https://www.youtube.com/watch?v%3DgmapHqguNJo">
                      Example video
                    </a>
                  </td>
                  <td>
                    Cuttable DXF flat pattern; shop drawings indicating outer
                    diameter, quantity, welds, and finishes.
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Turned</b>
                  </td>
                  <td>
                    <a href="https://www.haascnc.com/machines/lathes/st/models/standard/st-15.html">
                      Haas ST15 Lathe
                    </a>
                  </td>
                  <td>
                    All outer profile features accessible radially with
                    trapezoidal tool, and all inner profile features accessible
                    axially.
                  </td>
                  <td>
                    Keeping outer shaft close to a stock size can reduce costs
                    considerably due to shorter cycle times.
                  </td>
                  <td>
                    Operations list, DXF file of side profile, and oriented
                    original model.
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>CNC Milled</b>
                  </td>
                  <td>
                    <a href="https://www.fanucamerica.com/products/fanuc-robodrill-robomachine">
                      Fanuc Robodrill
                    </a>
                  </td>
                  <td>
                    Milled parts consisting of pockets aligned with clampable
                    faces.
                  </td>
                  <td>
                    All features should be representable as extruded cuts.
                    Fillets and chamfers on the bottom of pockets are generally
                    to be avoided.
                  </td>
                  <td>
                    Operations list and an oriented model for CAM programming.
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Additive</b>
                  </td>
                  <td>
                    <a href="https://formlabs.com/3d-printers/form-3/">
                      Formlabs Form3
                    </a>
                  </td>
                  <td>Additive processes are also known as 3D printing.</td>
                  <td>Thin walls are generally to be avoided.</td>
                  <td>
                    Packed AMF or STL files with parts oriented correctly.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="segmentRectangle" />
      </div>
    </div>
  );
}

export function Technology(props: any) {
  return (
    <div className="container-sm bg-white p-3">
      <h1 className="my-3">Technology</h1>
      <video
        width="100%"
        height="100%"
        autoPlay={true}
        loop={true}
        muted={true}
      >
        <source src="/static/images/tech.mp4" type="video/mp4" />
      </video>
      <h2>Kerfed Intelligent Manufacturing Engine</h2>
      <p>
        The Kerfed IME is a software system which turns 3D CAD models of
        mechanical assemblies to instant quotes, and orders to parts.
      </p>
      <p>
        The goal is to build a "hardware abstraction layer" which automatically
        handles all of the intermediate steps between data and parts, such as
        clicking on edge loops in CAM systems, packing DXF files, and countless
        other easily automated tasks.
      </p>
      <p>
        Kerfed’s spiritual predecessor is{' '}
        <a href="https://www.darpa.mil/program/adaptive-vehicle-make">
          DARPA's AVM/iFAB
        </a>
        . One of the insights behind the program was that rather than aspiring
        to replace every process with slice-based 3D printing techniques we
        should create a "hardware abstraction layer". This layer could divide
        parts between different fabrication processes and suppliers. At the
        time, iFAB required a lot of manual part annotation which made the dream
        of “3D model in, HMMWV out” include an intermediate step where each part
        needed substantial amounts of engineering time.
      </p>
      <p>
        The Kerfed Engine was developed by alumni of CMU's branch of the DARPA
        AVM program. Over the years since, we have developed the Kerfed Engine,
        an automated, robust and performant product. The system today handles
        flat and bent parts and identifies commercial bearings, pins, washers,
        and spacers in assemblies.
      </p>
      <p>
        Our goal is to add processes and increase the breadth of the COTS
        databases such that even complicated assemblies (i.e. a rocket, iPhone,
        or toaster) can be sourced in under a minute.
      </p>

      <div className="table-responsive">
        <table className="table">
          <tbody>
            <tr>
              <td>
                <h2>Flat Parts</h2>
                <p>
                  A flat part is a 2D profile extruded by 0.02” to 2.00”. These
                  parts can be quickly and precisely made by many great methods,
                  including waterjets and laser cutters.
                </p>
              </td>
              <td>
                <img className="img-fluid" src="/static/images/rend_flat.png" />
              </td>
            </tr>
            <tr>
              <td>
                <h2>Bent Parts</h2>
                <p>
                  A bent sheet metal part consists of flat sections connected by
                  bend lines. Modern CNC press brakes in good hands can produce
                  these quickly with no custom tooling required.
                </p>
              </td>
              <td>
                <img className="img-fluid" src="/static/images/rend_bent.png" />
              </td>
            </tr>
            <tr>
              <td>
                <h2>Slip Rolled Parts</h2>
                <p>
                  Slip or plate rolling is a common sheet metal operation where
                  parts are designed as thin-wall partial cylinders with a seam
                  as small as 0.5 degrees, in material up to 3/16" thick.
                </p>
              </td>
              <td>
                <img className="img-fluid" src="/static/images/rend_roll.png" />
              </td>
            </tr>

            <tr>
              <td>
                <h2>Turned Parts</h2>
                <p>
                  Single-fixture turning is any part which can be fixtured in a
                  lathe and then run in a single pass on a CNC lathe. This
                  reduces cost and increases precision.
                </p>
              </td>
              <td>
                <img className="img-fluid" src="/static/images/rend_turn.png" />
              </td>
            </tr>
            <tr>
              <td>
                <h2>COTS Parts</h2>
                <p>
                  The Kerfed Engine detects ball bearings and dowel pins to
                  auto- tolerance holes, and provides part numbers and
                  quantities from the assembly.
                </p>
              </td>
              <td>
                <img className="img-fluid" src="/static/images/rend_cots.png" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="segmentRectangle" />
    </div>
  );
}
