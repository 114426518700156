import React from 'react';
import { LinePrice } from '@kerfed/client';

import CircuralLoading from '../../CircuralLoading';
import OptionalPrice from '../OptionalPrice';

interface ItemRowPriceProps {
  loading?: boolean;
  enabled?: boolean;
  linePrice?: LinePrice | null;
}

const ItemRowPrice = ({
  loading = false,
  enabled = false,
  linePrice,
}: ItemRowPriceProps) => {
  return (
    <>
      <td>
        {!!loading ? (
          <CircuralLoading />
        ) : (
          <h2>
            <OptionalPrice
              enabled={enabled}
              price={linePrice?.unit}
              message={linePrice?.outcome?.errors?.[0]?.message}
            />
          </h2>
        )}
      </td>
      <td>
        {!!loading ? (
          <CircuralLoading />
        ) : (
          <h2>
            <OptionalPrice
              enabled={enabled}
              price={linePrice?.total}
              message={linePrice?.outcome?.errors?.[0]?.message}
            />
          </h2>
        )}
      </td>
    </>
  );
};

export default ItemRowPrice;
